function Input({
    required = false,
    type = "text",
    placeholder,
    value,
    onChange,
    hasError = false,
    errorMessage = "Something went wrong.",
}) {
    return (
        <div>
            <div className="relative">
                <input
                    required={required}
                    className={`w-full py-1 px-3 focus:outline-none focus:ring-1 border ${
                        hasError
                            ? "border-red-500 ring-red-500"
                            : "border-gray-300 focus:border-blue-500 ring-blue-500"
                    }`}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                {/* Show an error icon in the input */}
                {hasError && (
                    <div className="absolute top-0 right-0 h-full px-2 text-red-500 flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                )}
            </div>
            {/* Show an error message */}
            {hasError && (
                <div className="text-red-500 font-semibold text-sm mt-1">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

export default Input;
