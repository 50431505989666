import { useEffect } from "react";

function ProductSelector({
    updateFormContent,
    selectedProduct,
    setSelectedProduct,
}) {
    useEffect(() => {
        updateFormContent("productFamily", "Product Family", selectedProduct, true);
    }, [selectedProduct]);

    return (
        <div className="grid grid-cols-3">
            <div
                onClick={() => setSelectedProduct("email")}
                className={`flex flex-row items-center space-x-3 p-3 cursor-pointer ${
                    selectedProduct === "email"
                        ? "border border-blue-300 bg-blue-100"
                        : "border border-gray-300 bg-white"
                }`}
            >
                <img
                    className="h-7 w-7"
                    alt="Email Security"
                    src="/images/email_security_small.png"
                />
                <span>Email</span>
            </div>
            <div
                onClick={() => setSelectedProduct("endpoint")}
                className={`flex flex-row items-center space-x-3 p-3 cursor-pointer ${
                    selectedProduct === "endpoint"
                        ? "border border-blue-300 bg-blue-100"
                        : "border border-gray-300 bg-white"
                }`}
            >
                <img
                    className="h-7 w-7"
                    alt="Network Security"
                    src="/images/endpoint_security_small.png"
                />
                <span>Endpoint</span>
            </div>

            <div
                onClick={() => setSelectedProduct("network")}
                className={`flex flex-row items-center space-x-3 p-3 cursor-pointer ${
                    selectedProduct === "network"
                        ? "border border-blue-300 bg-blue-100"
                        : "border border-gray-300 bg-white"
                }`}
            >
                <img
                    className="h-7 w-7"
                    alt="Network Security"
                    src="/images/network_security_small.png"
                />
                <span>Network</span>
            </div>
        </div>
    );
}

export default ProductSelector;
