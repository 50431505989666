import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../components/Button/Button";
import FormLabel from "../components/Form/FormLabel";
import Panel from "../components/Panel/Panel";
import ProductSelector from "../components/Selectors/ProductSelector";
import {
    emailValidator,
    endpointValidator,
    networkValidator,
} from "../utils/formValidators";
import ContactInformation from "./ContactInformation";
import EmailForm from "./EmailForm";
import EndpointForm from "./EndpointForm";
import NetworkForm from "./NetworkForm";

function Authenticated(props) {
    const [formContent, setFormContent] = useState([]);
    const [fileObject, setFileObject] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState("email");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    /**
     * Remove in production
     */
    useEffect(() => {
        console.log(formContent);
    }, [formContent]);

    const updateFormContent = (field, label, value, clearAll = false) => {
        /**
         * Check if the clear all option is true, empty the array
         */
        if (clearAll) {
            setFormContent([
                {
                    field,
                    label,
                    value,
                },
            ]);
        } else {
            /**
             * Check if the field exists already, if it does, we will
             * update it. If it doesn't, we will create it.
             */
            if (formContent.some((element) => element.field === field)) {
                const newState = formContent.map((obj) =>
                    obj.field === field ? { ...obj, value: value } : obj
                );

                setFormContent(newState);
            } else {
                setFormContent([{ field, label, value }, ...formContent]);
            }
        }
    };

    const validateForm = () => {
        /**
         * Set a default object
         */
        let validationObject = {
            passed: true,
            issues: [],
            data: null,
        };

        /**
         * Check what the selectedProduct is and validate
         * based on the fields expected and required
         */
        switch (selectedProduct) {
            case "email":
                validationObject = emailValidator(formContent);
                break;
            case "endpoint":
                validationObject = endpointValidator(formContent);
                break;
            case "network":
                validationObject = networkValidator(formContent);
                break;
        }

        /**
         * Toast any potential issues with the form to the
         * end user
         */
        if (validationObject.issues.length) {
            let list = validationObject.issues.map((issue, index) => {
                return (
                    <li key={index} className="mb-1">
                        {issue}
                    </li>
                );
            });

            let issueContainer = (
                <div>
                    <h3 className="text-red-500 font-semibold mb-2">
                        Please resolve the following issues:
                    </h3>
                    <ul>{list}</ul>
                </div>
            );

            toast.error(issueContainer, {
                duration: 1000 * validationObject.issues.length,
            });
        }

        if (!fileObject) {
            toast.error("Please upload a file", {
                duration: 6000,
            });

            return false;
        }

        if (validationObject.passed) {
            submitForm(validationObject.data);
        }

        return false;
    };

    const submitForm = (data) => {
        setIsSubmitting(true);
        console.log(data);

        /**
         * The first thing we need to do is upload
         * the file to the signed url we received from
         * the authentication step
         */
        let uploadBody = new FormData();

        /**
         * Get the file from the UI
         */
        uploadBody.append("file", fileObject);

        /**
         * Upload the file to S3
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/zip");

        var requestOptions = {
            headers: myHeaders,
            method: "PUT",
            body: uploadBody,
            redirect: "follow",
        };

        fetch(props.signedUrl, requestOptions)
            .then((response) => {
                /**
                 * Bundle the form data
                 */
            //    let formData = new FormData();

                /**
                 * Add the data to the FormData object
                 */
            //    formData.append("data", data);
            //    formData.append("url", props.signedUrl);

                /**
                 * Post the data to the AWS Lambda
                 */
                let submissionPromise = axios
                    .put(
                        `${process.env.REACT_APP_API}${process.env.REACT_APP_API_SUBMISSION}`,
                        {
                            data: data,
                            url: props.signedUrl,
                        }
                    )
                    .then(() => {
                        setIsSubmitting(false);
                        setIsSubmitted(true);
                    });

                toast.promise(submissionPromise, {
                    loading: "Submitting...",
                    success: "File submission successful",
                    error: "Something went wrong, please try again",
                });
            })
            .catch((error) => console.error(error));
    };

    return (
        <div>
            <Panel>
                <div className="flex flex-col space-y-3">
                    <ProductSelector
                        updateFormContent={updateFormContent}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                    />

                    {selectedProduct === "email" ? (
                        <EmailForm
                            updateFormContent={updateFormContent}
                            setFileObject={setFileObject}
                        />
                    ) : selectedProduct === "endpoint" ? (
                        <EndpointForm
                            updateFormContent={updateFormContent}
                            setFileObject={setFileObject}
                        />
                    ) : (
                        <NetworkForm
                            updateFormContent={updateFormContent}
                            setFileObject={setFileObject}
                        />
                    )}
                </div>
            </Panel>

            <Panel>
                <ContactInformation updateFormContent={updateFormContent} />
            </Panel>

            <div className="flex flex-row justify-end">
                <Button
                    disabled={isSubmitting}
                    color="red"
                    onClick={() => validateForm()}
                >
                    {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
            </div>
        </div>
    );
}
export default Authenticated;
