import { useEffect, useState } from "react";
import FormLabel from "../components/Form/FormLabel";
import Input from "../components/Form/Input";

function ContactInformation({ updateFormContent }) {
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");

    const handleContactNameChange = (value) => {
        setContactName(value);
        updateFormContent("contactName", "Contact Name", value);
    };

    const handleContactEmailChange = (value) => {
        setContactEmail(value);
        updateFormContent("contactEmail", "Contact Email", value);
    };

    return (
        <div className="flex flex-col space-y-3">
            <div>
                <FormLabel label={"Contact Name*"} />
                <Input
                    required
                    type="text"
                    value={contactName}
                    onChange={handleContactNameChange}
                />
            </div>
            <div>
                <div>
                    <FormLabel label={"Contact Email*"} />
                    <Input
                        required
                        type="email"
                        value={contactEmail}
                        onChange={handleContactEmailChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default ContactInformation;
