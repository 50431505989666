function Button({ children, disabled, type, onClick, color }) {
    const colorMapping = (color) => {
        const colors = {
            blue: "text-white bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 disabled:bg-blue-100",
            red: "text-white bg-red-500 hover:bg-red-700 focus:bg-red-700 disabled:bg-red-100",
            "dark-gray":
                "text-white bg-gray-800 hover:bg-gray-900 focus:bg-gray-900 disabled:bg-gray-300",
        };

        return colors[color] || colors["blue"];
    };
    return (
        <button
            disabled={disabled}
            type={type}
            className={`py-1 px-3 transition-colors duration-150 disabled:cursor-not-allowed ${colorMapping(
                color
            )}`}
            onClick={(e) => onClick(e)}
        >
            {children}
        </button>
    );
}

Button.defaultProps = {
    disabled: false,
    type: "button",
    color: "blue",
    onClick: () => {
        return null;
    },
};

export default Button;
