export const emailValidator = (form) => {
    const checkForProductFirst = form.find(
        (item) => item.field === "emailProduct"
    );

    if (!checkForProductFirst) {
        return {
            passed: false,
            issues: ["Please select an Email Product"],
        };
    }

    let expectedFields = [
        "productFamily",
        "emailProduct",
        "classification",
        "additionalDetails",
        "type",
        "etpRegion",
        "etpMessageId",
        "etpAlertId",
        "contactName",
        "contactEmail",
    ];

    let exRequiredFields = [
        {
            field: "emailProduct",
            errorText: "You need to select a product",
        },
        {
            field: "classification",
            errorText: "You need to select a classification",
        },
        {
            field: "additionalDetails",
            errorText: "You need to provide additional details",
        },
        {
            field: "contactName",
            errorText: "You need to provide a contact name",
        },
        {
            field: "contactEmail",
            errorText: "You need to provide a contact email",
        },
    ];

    let etpRequiredFields = [
        {
            field: "emailProduct",
            errorText: "You need to select a product",
        },
        {
            field: "classification",
            errorText: "You need to select a classification",
        },
        {
            field: "type",
            errorText: "You need to select a type AV/AS or AT",
        },
        {
            field: "etpRegion",
            errorText: "You need to select an ETP Region",
        },
        {
            field: "etpMessageId",
            errorText: "You need to provide an ETP Message ID",
        },
        {
            field: "additionalDetails",
            errorText: "You need to provide additional details",
        },
        {
            field: "contactName",
            errorText: "You need to provide a contact name",
        },
        {
            field: "contactEmail",
            errorText: "You need to provide a contact email",
        },
    ];

    /**
     * Filter the array of form data to only include what's expected
     */
    let allowList = form.filter((item) => expectedFields.includes(item.field));

    /**
     * Check the EX product fields
     */
    return checkForProductFirst.value === "EX"
        ? fieldChecker(exRequiredFields, allowList)
        : fieldChecker(etpRequiredFields, allowList);
};

export const endpointValidator = (form) => {
    let expectedFields = [
        "productFamily",
        "classification",
        "alertName",
        "scVersion",
        "additionalDetails",
        "canShareWithBitDefender",
        "contactName",
        "contactEmail",
    ];

    let requiredFields = [
        {
            field: "classification",
            errorText: "You need to select a classification",
        },
        {
            field: "alertName",
            errorText: "You need to provide the Alert Name",
        },
        {
            field: "scVersion",
            errorText: "You need to provide your Security Content version",
        },
        {
            field: "additionalDetails",
            errorText: "You need to provide additional details",
        },
        {
            field: "contactName",
            errorText: "You need to provide a contact name",
        },
        {
            field: "contactEmail",
            errorText: "You need to provide a contact email",
        },
    ];

    /**
     * Filter the array of form data to only include what's expected
     */
    let allowList = form.filter((item) => expectedFields.includes(item.field));

    /**
     * Check the EX product fields
     */
    return fieldChecker(requiredFields, allowList);
};

export const networkValidator = (form) => {
    let expectedFields = [
        "productFamily",
        "classification",
        "additionalDetails",
        "contactName",
        "contactEmail",
    ];

    let requiredFields = [
        {
            field: "classification",
            errorText: "You need to select a classification",
        },
        {
            field: "additionalDetails",
            errorText: "You need to provide additional details",
        },
        {
            field: "contactName",
            errorText: "You need to provide a contact name",
        },
        {
            field: "contactEmail",
            errorText: "You need to provide a contact email",
        },
    ];

    /**
     * Filter the array of form data to only include what's expected
     */
    let allowList = form.filter((item) => expectedFields.includes(item.field));

    /**
     * Check the EX product fields
     */
    return fieldChecker(requiredFields, allowList);
};

export const fieldChecker = (requiredFields, submittedFields) => {
    let issues = [];
    let validated = true;

    requiredFields.forEach((item) => {
        let submittedItem = submittedFields.filter(
            (field) => field.field === item.field
        );

        if (submittedItem.length < 1) {
            validated = false;
            issues.push(item.errorText);
        }
    });

    return {
        passed: validated,
        issues,
        data: submittedFields,
    };
};
