function ButtonLoader({ className = "h-6 w-6" }) {
    return (
        <svg
            className={className}
            version="1.1"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 50"
            x="0px"
            y="0px"
            enableBackground="new 0 0 0 0"
        >
            <circle fill="#fff" stroke="none" cx="6" cy="25" r="6">
                <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1"
                />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="25" r="6">
                <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2"
                />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="25" r="6">
                <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3"
                />
            </circle>
        </svg>
    );
}

export default ButtonLoader;
