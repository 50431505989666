import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import FormLabel from "../components/Form/FormLabel";
import Textarea from "../components/Form/Textarea";
import Input from "../components/Form/Input";
import { bytesToSize } from "../utils/bytesToSize";

function EmailForm({ updateFormContent, setFileObject }) {
    const [subProduct, setSubProduct] = useState("");
    const [classification, setClassification] = useState("");
    const [additionalDetails, setAdditionalDetails] = useState("");
    const [type, setType] = useState("");
    const [etpRegion, setEtpRegion] = useState("");
    const [etpMessageId, setEtpMessageId] = useState("");
    const [etpAlertId, setEtpAlertId] = useState("");

    const handleSubProductChange = (value) => {
        setSubProduct(value);
        updateFormContent("emailProduct", "Email Product", value);
    };

    const handleClassificationChange = (value) => {
        setClassification(value);
        updateFormContent("classification", "Classification", value);
    };

    const handleAdditionalDetailsChange = (value) => {
        setAdditionalDetails(value);
        updateFormContent("additionalDetails", "Additional Details", value);
    };

    const handleTypeChange = (value) => {
        setType(value);
        updateFormContent("type", "Type", value);
    };

    const handleEtpRegionChange = (value) => {
        setEtpRegion(value);
        updateFormContent("etpRegion", "ETP Region", value);
    };

    const handleEtpMessageIdChange = (value) => {
        setEtpMessageId(value);
        updateFormContent("etpMessageId", "ETP Message ID", value);
    };

    const handleEtpAlertIdChange = (value) => {
        setEtpAlertId(value);
        updateFormContent("etpAlertId", "ETP Alert ID", value);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFileObject(acceptedFiles[0]);
    }, []);
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            onDrop,
            // accept: "application/zip",
            maxFiles: 1,
            maxSize: 20971520,
        });
    const [fileUploadhasError, setFileUploadHasError] = useState(false);

    return (
        <div className="flex flex-col space-y-3">
            <div>
                <FormLabel label={"Product*"} />
                <div className="flex flex-row space-x-3">
                    <label htmlFor="exRadioButton">
                        <input
                            id="exRadioButton"
                            className="mr-2"
                            type="radio"
                            value="EX"
                            checked={subProduct === "EX"}
                            onChange={(e) => handleSubProductChange("EX")}
                        />
                        EX
                    </label>
                    <label htmlFor="etpRadioButton">
                        <input
                            id="etpRadioButton"
                            className="mr-2"
                            type="radio"
                            value="ETP"
                            checked={subProduct === "ETP"}
                            onChange={(e) => handleSubProductChange("ETP")}
                        />
                        ETP
                    </label>
                </div>
            </div>
            {subProduct ? (
                <div className="flex flex-col space-y-3">
                    <div>
                        <FormLabel label="Classification*" />
                        <div className="flex flex-row space-x-3">
                            <label htmlFor="fpRadioButton">
                                <input
                                    id="fpRadioButton"
                                    className="mr-2"
                                    type="radio"
                                    value="FP"
                                    checked={classification === "FP"}
                                    onChange={(e) =>
                                        handleClassificationChange("FP")
                                    }
                                />
                                False Positive
                            </label>
                            <label htmlFor="fnRadioButton">
                                <input
                                    id="fnRadioButton"
                                    className="mr-2"
                                    type="radio"
                                    value="FN"
                                    checked={classification === "FN"}
                                    onChange={(e) =>
                                        handleClassificationChange("FN")
                                    }
                                />
                                False Negative
                            </label>
                        </div>
                    </div>
                    {subProduct === "ETP" && (
                        <div className="flex flex-col space-y-3">
                            <div>
                                <FormLabel label="Type*" />
                                <div className="flex flex-row space-x-3">
                                    <label htmlFor="avasRadioButton">
                                        <input
                                            id="avasRadioButton"
                                            className="mr-2"
                                            type="radio"
                                            value="AV/AS"
                                            checked={type === "AV/AS"}
                                            onChange={(e) =>
                                                handleTypeChange(
                                                    "AV/AS"
                                                )
                                            }
                                        />
                                        Anti-Virus / Anti-Spam
                                    </label>
                                    <label htmlFor="atRadioButton">
                                        <input
                                            id="atRadioButton"
                                            className="mr-2"
                                            type="radio"
                                            value="AT"
                                            checked={type === "AT"}
                                            onChange={(e) =>
                                                handleTypeChange("AT")
                                            }
                                        />
                                        Advanced Threat
                                    </label>
                                </div>
                            </div>
                            <div>
                                <FormLabel label="ETP Region*" />
                                <Input
                                    type="text"
                                    placeholder={"Enter ETP Region..."}
                                    value={etpRegion}
                                    onChange={handleEtpRegionChange}
                                />
                            </div>
                            <div>
                                <FormLabel label="ETP Message ID*" />
                                <Input
                                    type="text"
                                    placeholder={"Enter ETP Message ID..."}
                                    value={etpMessageId}
                                    onChange={handleEtpMessageIdChange}
                                />
                            </div>
                            <div>
                                <FormLabel label="ETP Alert ID (if available)" />
                                <Input
                                    type="text"
                                    placeholder={"Enter ETP Alert ID..."}
                                    value={etpAlertId}
                                    onChange={handleEtpAlertIdChange}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <FormLabel label="Additional Details*" />
                        <Textarea
                            placeholder={"Enter any additional details..."}
                            value={additionalDetails}
                            onChange={handleAdditionalDetailsChange}
                        />
                        <p className="text-sm text-gray-500">
                            Please provide any information or justification to
                            assist in the investigation of this{" "}
                            {classification === "FP"
                                ? "False Positive"
                                : "False Negative"}
                            .
                        </p>
                    </div>
                    <div>
                        <FormLabel label="File*" />
                        <div
                            {...getRootProps()}
                            className={`${
                                fileUploadhasError
                                    ? "bg-red-100 border-red-300"
                                    : acceptedFiles.length
                                    ? "bg-green-100 border-green-300"
                                    : isDragActive
                                    ? "bg-teal-100 border-teal-500"
                                    : "bg-white border-gray-300"
                            } w-full rounded text-center p-6 border border-dashed mb-1`}
                        >
                            <input {...getInputProps()} />
                            {acceptedFiles.length ? (
                                acceptedFiles.map((file, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-row w-full justify-center items-center"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4 mr-2"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                                        </svg>
                                        {file.path} ({bytesToSize(file.size)})
                                    </div>
                                ))
                            ) : isDragActive ? (
                                <p>Drop the files here ...</p>
                            ) : (
                                <div>
                                    <p>
                                        Drag & drop a .zip file here, or click
                                        to select a file
                                    </p>
                                    <em className="text-sm">
                                        Only *.zip files following the
                                        guidelines below will be accepted.
                                    </em>
                                </div>
                            )}
                        </div>
                        <p className="text-sm text-gray-500">
                            Original email in msg/eml format (not a
                            forwarded/altered email), xml, alert email or Triage
                            Package if available.
                        </p>
                    </div>
                </div>
            ) : (
                <div className="p-3 text-center border border-orange-300 bg-orange-100 text-orange-800">
                    Please select a product before continuing.
                </div>
            )}
        </div>
    );
}

export default EmailForm;
