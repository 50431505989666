function Textarea({ placeholder, value, onChange, hasError }) {
    return (
        <textarea
            placeholder={placeholder}
            rows="3"
            className={`w-full py-1 px-3 focus:outline-none focus:ring-1 border ${
                hasError
                    ? "border-red-500 ring-red-500"
                    : "border-gray-300 focus:border-blue-500 ring-blue-500"
            }`}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
}

Textarea.defaultProps = {
    placeholder: "Enter text here...",
    value: "",
    onChange: () => {
        return null;
    },
    hasError: false,
};

export default Textarea;
