import { useState } from "react";
import Helmet from "react-helmet";
import Container from "./components/Layout/Container";
import Layout from "./components/Layout/Layout";
import Panel from "./components/Panel/Panel";
import Unauthenticated from "./sections/Unauthenticated";
import Authenticated from "./sections/Authenticated";
import { Toaster } from "react-hot-toast";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [signedUrl, setSignedUrl] = useState("");

    return (
        <Layout>
            <Helmet>
                <title>Sample Submission | Trellix</title>
            </Helmet>
            <Toaster
                position="bottom-right"
                toastOptions={{
                    duration: 5000,
                    success: {
                        iconTheme: {
                            primary: "#6A823A",
                        },
                    },
                    error: {
                        iconTheme: {
                            primary: "#B2282C",
                        },
                    },
                }}
            />
            <Container>
                <div className="mb-3">
                    <img
                        alt="Trellix Logo"
                        src="/images/trellix_logo_big.png"
                        className="h-12 w-auto mb-6"
                    />
                    <h2 className="text-gray-600 text-xl tracking-wide mb-3">
                        Trellix Sample Submission
                    </h2>
                    <p className="text-gray-700">
                        Use the form below to submit samples to Trellix support.
                        Please provide as much information as possible to allow
                        support to investigate your submission in depth.
                    </p>
                </div>
                {!isAuthenticated ? (
                    <Unauthenticated
                        setIsAuthenticated={setIsAuthenticated}
                        setSignedUrl={setSignedUrl}
                    />
                ) : (
                    <Authenticated signedUrl={signedUrl} />
                )}
            </Container>
        </Layout>
    );
}

export default App;
