import { useState } from "react";
import axios from "axios";
import Panel from "../components/Panel/Panel";
import FormLabel from "../components/Form/FormLabel";
import Input from "../components/Form/Input";
import Button from "../components/Button/Button";
import ButtonLoader from "../components/Feedback/ButtonLoader";

function Unauthenticated({ setIsAuthenticated, setSignedUrl }) {
    const [accessKey, setAccessKey] = useState("");
    const [accessKeyHasError, setAccessKeyHasError] = useState(false);
    const [accessKeyLoading, setAccessKeyLoading] = useState(false);

    const submitAccessKey = (e) => {
        e.preventDefault();
        setAccessKeyLoading(true);
        axios
            .put(
                `${process.env.REACT_APP_API}${process.env.REACT_APP_API_CONFIRMATION}`,
                {
                    id: accessKey,
                }
            )
            .then(({ data }) => {
                if (data.result) {
                    setAccessKeyHasError(false);
                    setAccessKeyLoading(false);
                    setIsAuthenticated(true);
                    setSignedUrl(data.result);
                } else {
                    setAccessKeyHasError(true);
                    setAccessKeyLoading(false);
                    setIsAuthenticated(false);
                    setSignedUrl(null);
                }
            });
    };

    return (
        <Panel>
            <form onSubmit={(e) => submitAccessKey(e)}>
                <div className="flex-col space-y-3">
                    <div>
                        <FormLabel label="Enter Access Key*" />
                        <Input
                            required={true}
                            type="password"
                            placeholder={"Enter access key..."}
                            value={accessKey}
                            onChange={setAccessKey}
                            hasError={accessKeyHasError}
                            errorMessage={"Please provide a valid access key."}
                        />
                    </div>
                    <Button
                        disabled={accessKeyLoading}
                        color="red"
                        type="submit"
                    >
                        {accessKeyLoading ? <ButtonLoader /> : "Submit"}
                    </Button>
                </div>
            </form>
        </Panel>
    );
}

export default Unauthenticated;
